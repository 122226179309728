import React from 'react';
import { Spoiler, Table } from '@mantine/core';
import Layout from '../../components/Layout';
import blogd1 from '../../assets/images/2024/maryland-luth/1.jpeg';
import blogd2 from '../../assets/images/2024/maryland-luth/2.jpeg';
import blogd3 from '../../assets/images/2024/maryland-luth/3.jpeg';
import blogd4 from '../../assets/images/2024/maryland-luth/4.jpeg';
import blogd5 from '../../assets/images/2024/maryland-luth/5.jpeg';
import blogd6 from '../../assets/images/2024/maryland-luth/6.jpeg';
import blogd7 from '../../assets/images/2024/maryland-luth/7.jpeg';
import blogd8 from '../../assets/images/2024/maryland-luth/8.jpeg';
import blogd9 from '../../assets/images/2024/maryland-luth/9.jpeg';
import blogd10 from '../../assets/images/2024/maryland-luth/11.jpeg';
import blogd11 from '../../assets/images/2024/maryland-luth/10.jpeg';
import blogd12 from '../../assets/images/2024/maryland-luth/12.jpeg';
import blogd13 from '../../assets/images/2024/maryland-luth/13.jpeg';

import NavOne from '../../components/NavOne';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';

const NewsDetails = () => {
  //   const subs = [
  //     {
  //       SNO: '1',
  //       PRESENTERS: 'DR. A.K Toye',
  //       SESSION: 'CASE 1',
  //     },
  //     {
  //       SNO: '2',
  //       PRESENTERS: 'Dr. O.D Tom- AYEGUNLE',
  //       SESSION: 'CASE 2',
  //     },
  //     {
  //       SNO: '3',
  //       PRESENTERS: 'DR. T. AYO- Olagunju',
  //       SESSION: 'Timing of initiation of Dialysis AKI',
  //     },
  //     {
  //       SNO: '4',
  //       PRESENTERS: 'Dr. D. Ezuduemoih',
  //       SESSION: 'Timing of initiation of Dialysis CKD',
  //     },
  //     {
  //       SNO: '2',
  //       PRESENTERS: 'Dr. B. T. BELLO/ Dr. R. W. BRAIMOH',
  //       SESSION: 'Overview',
  //     },
  //   ];

  //   const subRows = subs.map((element) => (
  //     <tr key={element.SNO}>
  //       <td>{element.SNO}</td>
  //       <td>{element.PRESENTERS}</td>
  //       <td>{element.SESSION}</td>
  //     </tr>
  //   ));

  return (
    <section className='blog-details'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='blog-one__single'>
              <div className='blog-one__image'>
                <img src={blogd1} alt='' />
              </div>
              <div className='blog-one__content text-center'>
                <h2 className='blog-one__title'>
                  University of Maryland School of Medicine Collaborates with
                  Lagos University Teaching Hospital on Non-Communicable
                  Diseases
                </h2>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  The Lagos University Teaching Hospital (LUTH) has announced a
                  significant collaboration with the University of Maryland
                  School of Medicine to address non-communicable diseases (NCDs)
                  such as cancer, sickle cell disease, hypertension, and
                  diabetes. This partnership, which builds on over two decades
                  of cooperation in the field of infectious diseases, aims to
                  enhance research, capacity building, and patient care in
                  Nigeria.
                  <br />
                  {/* <Table fontSize='md' striped>
                    <thead>
                      <tr>
                        <th>SNO</th>
                        <th>PRESENTERS</th>
                        <th>SESSION</th>
                      </tr>
                    </thead>
                    <tbody>{subRows}</tbody>
                  </Table> */}
                  Speaking at the event, Chief Medical Director (LUTH) Professor
                  Wasiu Lanre Adeyemo (FAS), expressed enthusiasm about the
                  collaboration. "The University of Maryland's presence here is
                  a testament to the importance of building global partnerships.
                  With their support, we are committed to advancing research and
                  capacity building in critical areas such as cancer and sickle
                  cell disease. This collaboration is aligned with the Federal
                  Ministry of Health's directives under the leadership of
                  Professor Muhammad Ali Pate and Dr. Tunji Alausa, which
                  emphasizes the need for sustainable healthcare infrastructure
                  and human resource development in Nigeria."
                  <br />
                  Professor Adeyemo also highlighted the challenges of brain
                  drain and the need to retain skilled personnel within the
                  country. "To keep our healthcare professionals in Nigeria, we
                  must provide them with opportunities to grow, collaborate, and
                  make meaningful contributions to global research. This
                  partnership with the University of Maryland offers such
                  opportunities, and we are confident it will encourage our
                  younger generation of healthcare workers to stay and
                  contribute to the development of our healthcare system."
                  <br />
                  Mark Gladwin, the Dean of the School of Medicine and Vice
                  President of Medical Affairs at the University of Maryland,
                  emphasized the importance of extending their longstanding
                  partnership with Nigeria. "For over 20 years, the University
                  of Maryland has partnered with Nigeria through the Institute
                  for Human Virology to combat infectious diseases like HIV and
                  tuberculosis. Today, we are here to address the next
                  generational challenge—non-communicable diseases. Nigeria has
                  the highest prevalence of sickle cell disease globally, and
                  our goal is to improve the lives of patients affected by this
                  and other NCDs through research, capacity building, and
                  collaborative care."
                  <br />
                </p>
                <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd2} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd3} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  {/* Consultative Services Ongoing at the Outreach */}
                </span>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  Professor Taofeek Kunle Owonikoko, a renowned oncologist and
                  the leader of the cancer center at the University of Maryland,
                  also spoke at the event. "As non-communicable diseases become
                  more prevalent in low to middle-income countries like Nigeria,
                  it is crucial to build local capacity and expertise. Over the
                  next decade, we aim to work closely with both public and
                  private institutions in Nigeria to address the challenges
                  posed by NCDs. This collaboration is not only about bringing
                  our expertise into the country but also about learning from
                  local experts and finding sustainable solutions together."
                  <br />
                  This partnership is expected to last initially for four to
                  five years, with the possibility of renewal based on the
                  success of ongoing projects. The collaboration marks a
                  significant step forward in addressing the growing burden of
                  non-communicable diseases in Nigeria and ensuring that the
                  country's healthcare system is equipped to meet these
                  challenges.
                  <br />
                  This initiative is being carried out in close collaboration
                  with the College of Medicine, University of Lagos, and
                  involves both clinical and non-clinical staff at LUTH. The
                  hospital looks forward to the positive impact this partnership
                  will have on the Nigerian healthcare system, particularly in
                  the areas of research, patient care, and the retention of
                  skilled healthcare professionals within the country.
                </p>
                <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd4} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  {/* attendees at the seminar */}
                </span>

                {/* <table style='width:100%'>
                  <tr>
                    <th>Date</th>
                    <th>Activities</th>
                    <th>Venue</th>
                  </tr>
                  <tr>
                    <td>Tuesday, 1st August</td>
                    <td>Webinar</td>
                    <td>@3:00pm</td>
                  </tr>
                  <tr>
                    <td>Wednesday, 2nd August, 2023</td>
                    <td>Awareness Creation in LUTH
                        <br />
                        Presentation by NANPAN LUTH @ Nurse's Seminar Room
                    </td>
                    <td>@ 8:30am
                        <br />
                        @11:00am
                    </td>
                  </tr>
                  <tr>
                    <td>Thursday, 3rd August</td>
                    <td>Train the trainers on BFHI @ Paediatrics Department</td>
                    <td>@9:00am - 2:00pm</td>
                  </tr>
                  <tr>
                    <td>Friday, 4th August</td>
                    <td>Outreach Program at Seriki's Compound, Haruna Street, Idi-Araba</td>
                    <td>@9:00am - 12:00noon</td>
                  </tr>
                  <tr>
                    <td>Friday, 4th August</td>
                    <td>Outreach Program at Seriki's Compound, Haruna Street, Idi-Araba</td>
                    <td>@1:00pm</td>
                  </tr>
                  <tr>
                    <td>Sunday, 6th August</td>
                    <td>Health Talk @ LUTH/CMUL Chaplaincy, Idi-Araba</td>
                    <td>@9:30am</td>
                  </tr>
                </table> */}
                {/* <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  She said that NSOANP had to do with providing affordable,
                  accessible and safe surgery to Nigerians that need it as part
                  of Universal Health Coverage. “This is as a result of World
                  Health Assembly Resolution in 2015 which mandated Member
                  States to include basic and Surgical Care as part of Universal
                  coverage in the country. “Federal Ministry of Health produced
                  the plan and most of the implementation takes place at the
                  State level. “That is why we started the dissemination to the
                  36 States and the Federal Capital Territory after approval was
                  given at the National Council of Health,”. She said that World
                  Health Assembly Resolution 68:15 says Surgical, Obstetrics,
                  Anesthesia and Nursing care are important components of
                  Universal Health Coverage, therefore, Surgeries are fully
                  integrated into Healthcare Services.
                </p> */}

                <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd5} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd6} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Sections of the Seminar
                </span>
                <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd7} height={370} />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd8} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd9} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Sections of the Seminar
                </span>
                <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd10} height={370} />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd11} height={370} />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd12} height={370} />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd13} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  More pictures
                </span>
              </div>
            </div>
            <div className='share-block'>
              <div className='left-block'>
                <p>
                  Tags: <a href='#none'></a>
                </p>
              </div>
              {/* <div className='social-block'>
              <a href='#none'>
                <i className='fab fa-twitter'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-facebook-f'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-instagram'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-dribbble'></i>
              </a>
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const GalleryPage = () => {
  return (
    <Layout pageTitle='Lagos University Hospital World Breastfeeding Week (WBW)  | LUTH News'>
      <NavOne />
      <PageHeader title='' />
      <NewsDetails />
      <Footer />
    </Layout>
  );
};

export default GalleryPage;
